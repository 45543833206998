import React from 'react'
import {Row, Col} from 'reactstrap'
import { useStaticQuery, graphql } from "gatsby"
import { getPostsFromQuery } from '../../../utils/utilFunctions'
import Blog from '../../../components/blog/layout-four'
import Heading from '../../../components/shared/heading'
import { SimilarArticlesFactory } from '../related-posts/SimilarArticlesFactory'
import {RelatedPostsWrap} from '../related-posts/related-posts.stc'

const ContentfulRelatedPosts = (props) => {
    const { relatedPosts, category, tags, currentArticleSlug, ...restProps } = props
    const articles = getPostsFromQuery(relatedPosts);
    const similarArticles = new SimilarArticlesFactory(articles, currentArticleSlug)
                                    .setMaxArticles(2)
                                    .setCategory(category)
                                    .setTags(tags)
                                    .getArticles()
    const {headingCSS, blogCSS} = restProps;

    return (
        <RelatedPostsWrap>
            <Row>
                <Col>
                    <Heading {...headingCSS}>Related Posts</Heading>
                </Col>
            </Row>
            <Row>
                {similarArticles.map((data, i) => (
                    <Col md={6} key={`related-${i}`}>
                        <Blog
                            {...blogCSS}
                            content={{
                                ...data.article
                            }}
                        />
                    </Col>
                ))}
            </Row>
        </RelatedPostsWrap>
    )
}

ContentfulRelatedPosts.defaultProps = {
    headingCSS: {
        as: 'h3',
        fontSize: '16px',
        fontWeight: 600,
        mb: '31px'
    },
    blogCSS: {
        mb: '45px'
    },
    relatedPosts: [],
}

export default ContentfulRelatedPosts