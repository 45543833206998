import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { getRecentPostsFromEdges } from '../../utils/utilFunctions'
import { Container, Row, Col } from 'reactstrap'
import SEO from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from '../../containers/layout/header'
import Footer from '../../containers/layout/footer'
import BlogMeta, { Category, Tags } from '../../components/blog/blog-meta'
import { Thumbnail, Video, Quote, Linked, Gallery } from '../../components/blog/blog-media'
import ModalVideo from '../../components/shared/modal-video'
import { slugify } from '../../utils/utilFunctions'
import SearchWidget from '../../containers/widgets/search'
import RecentPostWidget from '../../containers/widgets/recent-post'
import CategoryWidget from '../../containers/widgets/categories'
import AuthorWidget from '../../containers/widgets/author'
import ContentfulRelatedPosts from '../../containers/global/contentful-related-posts'
import SocialShare from '../../components/socials/social-share'
import {
    SinglePostArea,
    SinglePostWrap,
    PostMedia,
    PostHeader,
    PostTitle,
    PostMeta,
    PostFooter,
    PostShare,
    PostTags,
    SidebarWrap
} from '../single-blog/single-blog.stc.js'

const ContentfulSingleBlog = ({ data, pageContext, location, ...restProps }) => {
    const { author } = data.contentfulBlogPost;
    const authorData = author;
    const authorContent = {
        name: authorData.name,
        image: authorData.image.fixed,
        tagline: authorData.tagline,
        social: {
            twitter: authorData.twitter,
            facebook: authorData.facebook,
            instagram: authorData.instagram,
            linkedin: authorData.linkedin,
            youtube: authorData.youtube,
            github: authorData.github,
        },
    };

    const { categoriesSummary } = pageContext;
    const { dateSlug, slug, authorId } = data.contentfulBlogPost;
    const {
        category, date, format, title,
        video_link, 
        link, images, tags
    } = data.contentfulBlogPost;
    const image = data.contentfulBlogPost.image;
    const {text: quote_text, author: quote_author} = data.contentfulBlogPost.quote !== null ? data.contentfulBlogPost.quote : { };

    console.log("slug=" + slug)
    console.log("category=" + category)

    const {html} = data.contentfulBlogPost.body.childMarkdownRemark;
    let video_arr, video_id, video_channel;
    if(video_link){
        video_arr = video_link.split('=', -1);
        video_id = video_arr[1];
        video_channel = video_link.split(".")[1];
    }
    const [videoOpen, setVideoOpen] = useState(false);
    const modalVideoOpen = () => {
        setVideoOpen(true)
    }
    const modalVideoClose = () => {
        setVideoOpen(false)
    }

    let keys = [data.contentfulBlogPost.category, data.contentfulBlogPost.tags];

    const recentFilesystemPosts = [];
    const recentContentfulPosts = data.recentContentfulBlogPost.edges;
    const recentPosts = getRecentPostsFromEdges(recentFilesystemPosts, recentContentfulPosts);

    const relatedPosts = data.relatedPosts.edges.map( ({ node }) => {
        const {text: quote_text, author: quote_author} = node.quote !== null ? node.quote : { };
  
        return {
            node: {
              fields: {
                  slug: node.slug,
                  collection: 'contentfulPosts',
                  dateSlug: node.dateSlug,
              },
              frontmatter: {
                  sortKey: node.sortKey,
                  date: node.date,
                  format: node.format,
                  title: node.title,
                  video_link: node.video_link,
                  quote_text: quote_text,
                  quote_author: quote_author,
                  link: node.link,
                  image: node.image,
                  images: node.images,
                  category: node.category,
                  is_featured: node.is_featured,
                  recentImage: node.recentImage,
                  tags: node.tags
              },
              excerpt: node.body.childMarkdownRemark.excerpt,
          }
        }
    });

    return (
        <Layout>
             <SEO 
                title={title} 
                keywords={keys}
            />
            <Header authorData={authorData}/>
            <div className="main-content">
                <SinglePostArea {...restProps}>
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <SinglePostWrap>
                                    <PostMedia>
                                        {(format === 'image' || format === 'standard') && (
                                            <Thumbnail path={`/${slug}`} image={image} title={title}/>
                                        )}
                                        {format === 'video' && (
                                            <Video
                                                onClick={modalVideoOpen}
                                                poster={image}
                                                title={title}
                                            />
                                        )}
                                        {format === 'quote' && <Quote text={quote_text} author={quote_author}/>}
                                        {format === 'link' && <Linked link={link}/>}
                                        {format === 'gallery' && <Gallery images={images}/>}
                                    </PostMedia>
                                    <PostHeader>
                                        {category && <Category slug={`/category/${slugify(category)}`} text={category}/>}
                                        {title && <PostTitle>{title}</PostTitle>}
                                        {<PostMeta>
                                            {date && (
                                                <BlogMeta>
                                                    <Link to={`/date/${slugify(dateSlug)}`}>{date}</Link>
                                                </BlogMeta>
                                            )}
                                            {authorData && (
                                                <BlogMeta>
                                                    <Link to={`/author/${slugify(authorData.authorId)}`}>{authorData.name}</Link>
                                                </BlogMeta>
                                            )}
                                        </PostMeta>}
                                    </PostHeader>
                                    <div className="post-content" dangerouslySetInnerHTML={{__html: html}}/>
                                    <PostFooter>
                                        <PostShare>
                                            <h4>Share This:</h4>
                                            {<SocialShare
                                                title={title}
                                                slug={slug}
                                            />}
                                        </PostShare>
                                        <PostTags>
                                            <Tags tags={tags}/>
                                        </PostTags>
                                    </PostFooter>
                                </SinglePostWrap>
                                <ContentfulRelatedPosts relatedPosts={{edges: relatedPosts}} category={category} tags={tags} currentArticleSlug={slug}/>
                            </Col>
                            <Col lg={4}>
                                <SidebarWrap>
                                    <AuthorWidget 
                                        content={authorContent}
                                    />
                                    <SearchWidget/>
                                    <RecentPostWidget recentPosts={recentPosts}/>
                                    <CategoryWidget categoriesSummary={categoriesSummary}/>
                                </SidebarWrap>
                            </Col>
                        </Row>
                    </Container>
                </SinglePostArea>
            </div>
            <Footer/>
            <ModalVideo
                channel={video_channel}
                videoId={video_id}
                isOpen={videoOpen}
                onClose={modalVideoClose}
            />
        </Layout> 
    )
}

ContentfulSingleBlog.defaultProps = {
    categoriesSummary: {},
    featurePosts: {},
}

export const postQuery = graphql `
    query($slug: String!){
        recentContentfulBlogPost: allContentfulBlogPost(
            filter: { format : { eq: "image" } }
            sort: {order: DESC, fields: publishDate}
            limit: 4
        ) {
            edges {
                node {
                    ...RecentContentfulBlogPostField
                }
            }
        }
        relatedPosts: allContentfulBlogPost(
            filter: { format : { eq: "image" } }
            sort: {order: DESC, fields: publishDate}
            limit: 1000
        ) {
            edges {
                node {
                    ...MajorContentfulBlogPostField
                    tags
                    body {
                        childMarkdownRemark {
                            excerpt(format: PLAIN, pruneLength: 85)
                        }
                    }
                }
            }
        }
        contentfulBlogPost(slug: {eq: $slug}) {
            slug
            title
            category
            tags
            date: publishDate(formatString: "LL")
            format
            video_link
            quote {
                text
                author
            }
            link
            image: heroImage {
                sizes(maxWidth: 839, maxHeight: 455, quality: 100) {
                    ...GatsbyContentfulSizes_withWebp
                }
            }
            images {
                sizes(maxWidth: 839, maxHeight: 455, quality: 100) {
                    ...GatsbyContentfulSizes_withWebp
                }
            }
            author {
                name
                authorId
                tagline
                image {
                    fixed(width: 180, height: 180, quality: 100) {
                        ...GatsbyContentfulFixed_withWebp
                    }
                }   
                twitter
                facebook
                instagram
                linkedin
                youtube
                github
                shortBio {
                    shortBio
                }
            }
            body {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`;

export default ContentfulSingleBlog
